<template>
  <div class="view-wrapper">
    <v-toolbar class="elevation-0">
      {{ $localize('incoming_requests_for_confirmation_of_payment') }}
    </v-toolbar>
    <v-toolbar v-if="items.length === 0" no-gutters class="h-full elevation-0">
      <v-spacer/>{{$localize('the_list_of_applications_is_empty')}}<v-spacer/>
    </v-toolbar>
    <v-simple-table v-else
                    fixed-header
                    mobile-breakpoint="0"
                    style="height: calc(100vh - 146px)"
                    class="elevation-5"
    >
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center">{{ $localize('check_number') }}</th>
          <th class="text-center">{{ $localize('amount') }}</th>
          <th class="text-center">{{ $localize('bank') }}</th>
          <th class="text-center">{{ $localize('check_s_file') }}</th>
          <th class="text-center">{{ $localize('confirm') }}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="( item, rowIdx) in items">
          <tr :key="rowIdx">
            <td class="text-start">
              <v-row class="align-center justify-start">
                <v-avatar
                    class="blue-grey lighten-4 ma-2 my-1 ml-md-16"
                    dark
                    size="40"
                >
                  <v-icon>
                    mdi-credit-card-outline
                  </v-icon>
                </v-avatar>
                <div>
                  <div class="subtitle-1">{{item.transaction}}</div>
                  <div class="subtitle-2">{{ $dateTimeFormat(item.date)}}</div>
                </div>
              </v-row>
            </td>
            <td class="text-center">{{  $moneyFormat(item.sum) }}</td>
            <td class="text-center">{{ item.bank }}</td>
            <td class="text-center">
              <v-btn icon @click="downloadFile(item.fileUid)">
                <v-icon>mdi-file-eye-outline</v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <v-btn icon @click="openDialog(item.uid)">
                <v-icon>mdi-check-circle-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="dialog" max-width="560px">
      <v-card>
        <v-card-title class="text-h5"> {{ $localize('are_you_sure_you_want_to_confirm_payment') }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">{{$localize('cancel')}}</v-btn>
          <v-btn
              color="success"
              @click="ok">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PaymentsProcessing",
  data : () => ({
    items: [],
    dialog: false,
    paymentUid: undefined
  }),

  mounted() {
    this.init()
  },

  methods:{
    async init(){
      const res = await this.$fetch.get('api/payment/get-list')
      if(res) this.items = res
    },

    async downloadFile(fileUid){
      const res = await this.$fetch.get('api/file/custom-download?uid=' + fileUid)
      if(res.resource){
        //custom
        const byteCharacters  = atob(res.resource)
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        //custom
        window.open(window.URL.createObjectURL(new Blob([byteArray], {type: res.mime})), '_blank');
      }
    },

    openDialog(uid){
      this.paymentUid = uid
      this.dialog = true
    },

    closeDialog(){
      this.paymentUid = undefined
      this.dialog = false
    },

    ok(){
      this.confirm(this.paymentUid)
    },

    async confirm(uid){
      this.closeDialog()
      this.$appUtils.setOverlay(true)
      const res = await this.$fetch.post('api/payment/complete?uid=' + uid)
      if(res) {
        await this.init()
        this.$appUtils.setOverlay(false)
        this.$store.dispatch('setError',{ show: true, type: 'success', message: this.$localize('success')})
      }else{
        this.$appUtils.setOverlay(false)
        this.$store.dispatch('setError',{ show: true, type: 'error', message: this.$localize('data_sending_error')})
      }
    }
  }
}
</script>
